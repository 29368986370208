<template>
  <div class="exhibit">
    <div class="text-title">
      <i>展览展示</i>
      <p>
        团队拥有向前可兼容，向后可拓展的高效敏捷开发平台，可快速效响应业务需求
      </p>
    </div>
    <div class="exhibit-img w">
      <span v-for="item of exhibitionDataStat" :key="item.id">
        <div class="imgs">
          <!-- <img :src="baseIP + imageShowAPI + item.imageUri" alt="" /> -->
          <!-- <img :src="item.contentImageId && baseIP + imageShowAPI + item.contentImage.waterMarkUri" alt="" /> -->
          <img v-if="item.contentImageId &&item.contentImage" :src="item.contentImageId && baseIP + imageShowAPI + item.contentImage.waterMarkUri" alt="" />
          <img v-else :src="defaultImage" alt="" />
        </div>
        <h4>{{item.title}}</h4>
      </span>
    </div>
    <div class="btn">
      <span @click="moreData"><em class="el-icon-arrow-down"></em> 查看更多</span>
    </div>
  </div>
</template>

<script>
  import { getColumnContent } from "../api/index.js";
  import store from "@/store";
  export default {
    data() {
      return {
        baseIP: process.env.VUE_APP_BASE_URL,
        imageShowAPI: "/yuan/files/getFileStream/",
        defaultImage: require('../assets/imgs/enterpriseDefault.jpg'),
        exhibitionData: [],
        exhibitionDataStat: [],
      };
    },
    created() {
      // 展览展示数据
      getColumnContent({ optionType: "6" }).then((res) => {
        if (res.code === 0) {

          this.exhibitionData = res.data.content;
          this.exhibitionDataStat = this.exhibitionData.slice(0, 6);
          console.log(res, 'ids')
        }
      });
    },
    methods: {
      moreData() {
        const length = this.exhibitionData.length;
        if (length > this.exhibitionDataStat.length) {
          this.exhibitionDataStat = this.exhibitionData.slice(
            0,
            this.exhibitionDataStat.length + 2
          );
        } else {
          this.$message({
            type: "warning",
            message: "没有更多数据了",
          });
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .text-title {
    width: 665px;
    line-height: 120px;
    margin: 0 auto;
    padding-top: 90px;
    margin-bottom: 72px;
    box-sizing: border-box;
    i {
      display: block;
      font-style: normal;
      height: 40px;
      line-height: 40px;
      font-size: 34px;
      color: #333333;
    }
    p {
      line-height: 40px;
      font-size: 16px;
      color: #999999;
      margin: 10px 0;
    }
  }
  .exhibit-img {
    display: flex;
    justify-content: left;
    flex-flow: wrap;
    span {
      display: inline-block;
      width: 679px;
      height: 476px;
      box-sizing: border-box;
      cursor: pointer;
      margin-bottom: 10px;
      //   box-shadow: 0px 5px 10px 0px rgba(56, 56, 56, 0.13);
      .imgs {
        width: 679px;
        height: 406px;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
          transition: all 0.9s;
        }
        img:hover {
          transform: scale(1.2);
        }
      }
      h4 {
        font-weight: 400;
        height: 54px;
        line-height: 54px;
        text-align: left;
        font-size: 22px;
        color: #333333;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        box-sizing: border-box;
        border-bottom: 1px solid #f1f1f1;
      }
    }
    span:nth-child(odd) {
      margin-right: 23px;
    }
  }
  .btn {
    margin: 50px 0;
    span {
      display: inline-block;
      width: 150px;
      height: 40px;
      line-height: 40px;
      cursor: pointer;
      font-size: 20px;
      color: #999999;
      em {
        margin-right: 10px;
      }
    }
  }
</style>